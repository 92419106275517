import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router"
import store from "@/store"
import { Mutations, Actions } from "@/store/enums/StoreEnums"
import JwtService from "@/core/services/JwtService"
import NProgress from "nprogress"
import "nprogress/nprogress.css"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
      isAdmin: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: false,
        },
      },
      {
        path: "/notifications/add",
        name: "notifications-add",
        component: () => import("@/views/users/notifications/Add.vue"),
        meta: {
          pageTitle: "افزودن اعلان",
          breadcrumbs: ["افزودن اعلان"],
          justAdmin: false,
        },
      },
      {
        path: "/sign/add",
        name: "sign-add",
        component: () => import("@/views/users/sign/Add.vue"),
        meta: {
          pageTitle: "مدیریت امضاء",
          breadcrumbs: ["مدیریت امضاء"],
          justAdmin: false,
        },
      },
      {
        path: "/profile/password",
        name: "profile-password",
        component: () => import("@/views/admin/profile/ChangePassword.vue"),
        meta: {
          pageTitle: "تغییر گذرواژه",
          breadcrumbs: ["تغییر گذرواژه"],
          justAdmin: true,
        },
      },
      {
        path: "/notifications/edit/:id",
        name: "notifications-edit",
        component: () => import("@/views/users/notifications/Edit.vue"),
        meta: {
          pageTitle: "ویرایش اعلان",
          breadcrumbs: ["ویرایش اعلان"],
          justAdmin: false,
        },
      },
      {
        path: "/devices",
        name: "admin-devices",
        component: () => import("@/views/admin/devices/List.vue"),
        meta: {
          pageTitle: "لیست تابلوهای دیجیتال",
          breadcrumbs: ["لیست تابلوهای دیجیتال"],
          justAdmin: true,
        },
      },
      {
        path: "/admin/devices/:id",
        name: "admin-devices-of",
        component: () => import("@/views/admin/devices/ListCallPoint.vue"),
        meta: {
          pageTitle: "لیست تابلوهای دیجیتال",
          breadcrumbs: ["لیست تابلوهای دیجیتال"],
          justAdmin: true,
        },
      },
      {
        path: "/ads",
        name: "admin-ads",
        component: () => import("@/views/admin/ads/List.vue"),
        meta: {
          pageTitle: "لیست آگهی ها",
          breadcrumbs: ["لیست آگهی ها"],
          justAdmin: true,
        },
      },
      {
        path: "/admin/call-point/add",
        name: "admin-call-point-add",
        component: () => import("@/views/admin/call-point/add.vue"),
        meta: {
          pageTitle: "افزودن نقطه تماس",
          breadcrumbs: ["لیست نقطه تماس ها", "افزودن نقطه تماس"],
          justAdmin: true,
        },
      },
      {
        path: "/admin/call-point/edit/:id",
        name: "admin-call-point-edit",
        component: () => import("@/views/admin/call-point/edit.vue"),
        meta: {
          pageTitle: "ویرایش نقطه تماس",
          breadcrumbs: ["لیست نقطه تماس ها", "ویرایش نقطه تماس"],
          justAdmin: true,
        },
      },
      {
        path: "/admin/devices/add",
        name: "admin-devices-add",
        component: () => import("@/views/admin/devices/Add.vue"),
        meta: {
          pageTitle: "افزودن دستگاه",
          breadcrumbs: ["لیست دستگاه ها", "افزودن دستگاه"],
          justAdmin: true,
        },
      },
      {
        path: "/admin/devices/edit/:id",
        name: "admin-devices-edit",
        component: () => import("@/views/admin/devices/Edit.vue"),
        meta: {
          pageTitle: "ویرایش دستگاه",
          breadcrumbs: ["لیست نقطه دستگاه ها", "ویرایش دستگاه"],
          justAdmin: true,
        },
      },
      {
        path: "/admin/ads/add",
        name: "admin-ads-add",
        component: () => import("@/views/admin/ads/Add.vue"),
        meta: {
          pageTitle: "افزودن آگهی",
          breadcrumbs: ["لیست آگهی ها", "افزودن آگهی"],
          justAdmin: true,
        },
      },
      {
        path: "/admin/ads/edit/:id",
        name: "admin-ads-edit",
        component: () => import("@/views/admin/ads/Edit.vue"),
        meta: {
          pageTitle: "ویرایش آگهی",
          breadcrumbs: ["لیست آگهی ها", "ویرایش آگهی"],
          justAdmin: true,
        },
      },
      {
        path: "/admin/users/list",
        name: "admin-users-list",
        component: () => import("@/views/admin/users/List.vue"),
        meta: {
          pageTitle: "لیست کاربران",
          breadcrumbs: ["لیست کاربران"],
          justAdmin: true,
        },
      },
      {
        path: "/admin/user/add",
        name: "admin-user-add",
        component: () => import("@/views/admin/users/Add.vue"),
        meta: {
          pageTitle: "افزودن کاربر",
          breadcrumbs: ["لیست کاربران", "افزودن کاربر"],
          justAdmin: true,
        },
      },
      {
        path: "/admin/user/edit/:id",
        name: "admin-user-edit",
        component: () => import("@/views/admin/users/Edit.vue"),
        meta: {
          pageTitle: "ویرایش کاربر",
          breadcrumbs: ["لیست کاربران", "ویرایش کاربر"],
          justAdmin: true,
        },
      },
      {
        path: "/admin/profile/password",
        name: "admin-profile-password",
        component: () => import("@/views/admin/profile/ChangePassword.vue"),
        meta: {
          pageTitle: "تغییر گذرواژه",
          breadcrumbs: ["تغییر گذرواژه"],
          justAdmin: true,
        },
      },
      {
        path: "/admin/notifications/list",
        name: "admin-notifications-list",
        component: () => import("@/views/admin/notifications/List.vue"),
        meta: {
          pageTitle: "لیست اعلامیه ها",
          breadcrumbs: ["لیست اعلامیه ها"],
          justAdmin: true,
        },
      },
      {
        path: "/admin/notifications/list/:id",
        name: "admin-notifications-list-of",
        component: () => import("@/views/admin/notifications/ListCallPoint.vue"),
        meta: {
          pageTitle: "لیست اعلامیه ها",
          breadcrumbs: ["لیست اعلامیه ها"],
          justAdmin: true,
        },
      },
      {
        path: "/admin/notifications/add",
        name: "admin-notifications-add",
        component: () => import("@/views/admin/notifications/Add.vue"),
        meta: {
          pageTitle: "افزودن اعلان",
          breadcrumbs: ["افزودن اعلان"],
          justAdmin: true,
        },
      },
      {
        path: "/admin/notifications/edit/:id",
        name: "admin-notifications-edit",
        component: () => import("@/views/admin/notifications/Edit.vue"),
        meta: {
          pageTitle: "افزودن اعلان",
          breadcrumbs: ["افزودن اعلان"],
          justAdmin: true,
        },
      },
      {
        path: "/admin/tickets",
        name: "admin-tickets",
        component: () => import("@/views/admin/tickets/List.vue"),
        meta: {
          pageTitle: "تیکت ها",
          breadcrumbs: ["تیکت ها"],
          justAdmin: true,
        },
      },
      {
        path: "/admin/tickets/add/:user",
        name: "admin-tickets-add",
        component: () => import("@/views/admin/tickets/Add.vue"),
        meta: {
          pageTitle: "ارسال تیکت",
          breadcrumbs: ["ارسال تیکت", "تیکت ها"],
          justAdmin: true,
        },
      },
      {
        path: "/admin/ticket/:user/:id",
        name: "admin-ticket",
        component: () => import("@/views/admin/tickets/Ticket.vue"),
        meta: {
          pageTitle: "تیکت",
          breadcrumbs: ["تیکت", "تیکت ها"],
          justAdmin: true,
        },
      },
      {
        path: "/department",
        name: "department",
        component: () => import("@/views/admin/Department/list.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/department/edit/:id",
        name: "department-edit",
        component: () => import("@/views/admin/Department/edit.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/department/add",
        name: "department-add",
        component: () => import("@/views/admin/Department/add.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/admin/requests",
        name: "admin-requests",
        component: () => import("@/views/admin/requests/List.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/ads/statistics/:id",
        name: "ads-statistics",
        component: () => import("@/views/statistics/Show.vue"),
        meta: {
          pageTitle: "آمار نمایش",
          breadcrumbs: ["آمار نمایش"],
          justAdmin: false,
        },
      },
      {
        path: "/user/tickets",
        name: "user-tickets",
        component: () => import("@/views/users/tickets/List.vue"),
        meta: {
          pageTitle: "تیکت ها",
          breadcrumbs: ["تیکت ها"],
          justAdmin: false,
        },
      },
      {
        path: "/user/tickets/add",
        name: "user-tickets-add",
        component: () => import("@/views/users/tickets/Add.vue"),
        meta: {
          pageTitle: "ارسال تیکت",
          breadcrumbs: ["ارسال تیکت", "تیکت ها"],
          justAdmin: false,
        },
      },
      {
        path: "/user/ticket/:id",
        name: "user-ticket",
        component: () => import("@/views/users/tickets/Ticket.vue"),
        meta: {
          pageTitle: "تیکت",
          breadcrumbs: ["تیکت", "تیکت ها"],
          justAdmin: false,
        },
      },
    ],
  },
  {
    path: "/advertiser",
    redirect: "/requests",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
      isAdmin: false,
    },
    children: [
      {
        path: "/requests",
        name: "requests",
        component: () => import("@/views/advertiser/Dashboard.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: false,
        },
      },
      {
        path: "/requests/add",
        name: "requests-add",
        component: () => import("@/views/advertiser/requests/Add.vue"),
        meta: {
          pageTitle: "ثبت درخواست",
          breadcrumbs: ["ثبت درخواست"],
          justAdmin: false,
        },
      },
      {
        path: "/advertiser/tickets",
        name: "advertiser-tickets",
        component: () => import("@/views/advertiser/tickets/List.vue"),
        meta: {
          pageTitle: "تیکت ها",
          breadcrumbs: ["تیکت ها"],
          justAdmin: false,
        },
      },
      {
        path: "/advertiser/tickets/add",
        name: "advertiser-tickets-add",
        component: () => import("@/views/advertiser/tickets/Add.vue"),
        meta: {
          pageTitle: "ارسال تیکت",
          breadcrumbs: ["ارسال تیکت", "تیکت ها"],
          justAdmin: false,
        },
      },
      {
        path: "/advertiser/ticket/:id",
        name: "advertiser-ticket",
        component: () => import("@/views/advertiser/tickets/Ticket.vue"),
        meta: {
          pageTitle: "تیکت",
          breadcrumbs: ["تیکت", "تیکت ها"],
          justAdmin: false,
        },
      },
      {
        path: "/advertiser/ads",
        name: "advertiser-ads",
        component: () => import("@/views/advertiser/ads/List.vue"),
        meta: {
          pageTitle: "تبلیغات من",
          breadcrumbs: ["تبلیغات من"],
          justAdmin: false,
        },
      },
      {
        path: "/advertiser/ads/statistics/:id",
        name: "advertiser-ads-statistics",
        component: () => import("@/views/statistics/Show.vue"),
        meta: {
          pageTitle: "آمار نمایش",
          breadcrumbs: ["آمار نمایش"],
          justAdmin: false,
        },
      },
    ]
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "ورود",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
      {
        path: "/payment-canceled",
        name: "payment-canceled",
        component: () =>
          import("@/views/crafted/authentication/PaymentCanceled.vue"),
        meta: {
          pageTitle: "Payment Canceled",
        },
      },
      {
        path: "/payment-done/:refId",
        name: "payment-done",
        component: () =>
          import("@/views/crafted/authentication/PaymentDone.vue"),
        meta: {
          pageTitle: "Payment Done",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  NProgress.set(0.1)

  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG)

  // verify auth token before each page change
  await store.dispatch(Actions.VERIFY_AUTH, { token: JwtService.getToken() })

  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {
    if (store.getters.isUserAuthenticated) {
      if (to.meta.isAdmin) {
        if (store.getters.currentUser.role !== 1 && store.getters.currentUser.role !== 2) {
          next({
            path: "/500",
          })
          return
        }
        if (typeof to.meta.justAdmin !== undefined && to.meta.justAdmin) {
          if (store.getters.currentUser.role !== 1) {
            next({
              path: "/500",
            })
            return
          }
        }
      }
      else {
        if (store.getters.currentUser.role !== 3) {
          next({
            path: "/500",
          })
          return
        }
      }
      next()
    } else {
      next({ name: "sign-in" })
    }
  } else {
    next()
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  })
})

router.afterEach(() => {
  setTimeout(() => NProgress.done(), 500)
})

export default router
